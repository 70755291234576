<template>
  <div>
    <eden-page-header
      :title="'Rider Directory'"
      :subtitle="'Edit Rider Profile'"
    />
    <rider-form
      :banks="banks"
      v-if="rider.uuid"
      :action="'edit'"
      :rider="rider"
      :loading="loading"
    />
    <eden-loader :loading="loading" v-else />
  </div>
</template>

<script>
import RiderForm from "@/components/MarketplaceLogistics/Directory/Riders/RiderForm.vue";
import details from "@/requests/vendors/details.js";
import riders from "@/requests/marketplace-logistics/riders";

export default {
  name: "RiderAdd",
  components: {
    RiderForm,
  },
  data() {
    return {
      banks: [],
      rider: {},
      loading: false,
    };
  },
  computed: {
    riderId() {
      return this.$route.params.id;
    },
  },
  async created() {
    await this.getBanks();
    this.getRider();
  },
  methods: {
    async getBanks() {
      this.loading = true;
      try {
        const res = await details.listBanks();
        this.banks = res.data.data;
      } catch (err) {
        this.$message.error(err.message);
      } finally {
        this.loading = false;
      }
    },
    getRider() {
      this.loading = true;
      riders
        .get(this.riderId)
        .then((response) => {
          if (response.data.status) {
            this.rider = response.data.data;
            this.rider.company_id = this.rider.company.id;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
